import moment from "moment";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getLotterySetSummaryByPeriodIdApi,
  getPeriodLotteryByPeriodYearApi,
} from "../../api/lottery";
import {
  confirmOrderApi,
  filterOrdersApi,
  filterOrderSummaryApi,
} from "../../api/order";
import Breadcrumb from "../../component/breadcrumb";
import ModalViewImage from "../../component/modal-view-image";
import Pagination from "../../component/pagination";
import {
  ILotterySetSummary,
  IPeriodLotteryWithMainResult,
} from "../../interface/lottery";
import Meta from "../../interface/meta";
import { IFilterOrder, IOrderSummary } from "../../interface/order";
import { selectApp, setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";
import ReactDatePicker from "react-datepicker";
import FileSaver from "file-saver";

const OrderList = () => {
  const dispatch = useAppDispatch();
  const { badge } = useAppSelector(selectApp);
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<IFilterOrder[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [periodLottery, setPeriodLottery] = useState<
    IPeriodLotteryWithMainResult[]
  >([]);
  const activePeriod = useAppSelector((state) => state.auth.activePeriod);
  const listYear = useMemo(() => {
    if (activePeriod) {
      return Array.from({ length: 11 }).map(
        (_, i) => activePeriod.period.year - i
      );
    }
    return Array.from({ length: 11 }).map((_, i) => moment().year() - i);
  }, [activePeriod]);
  const [selectedYear, setSelectedYear] = useState<number>(
    activePeriod?.period.year ?? moment().year()
  );
  const [selectedPeriod, setSelectedPeriod] =
    useState<IPeriodLotteryWithMainResult>();
  const [summary, setSummary] = useState<ILotterySetSummary>();
  const [summaryData, setSummaryData] = useState<IOrderSummary>();
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    periodId: "",
    paymentMethod: "",
    begin: 0,
    end: 0,
    orderNo: "",
    status: "",
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if ((!!start && !!end) || (!start && !end)) {
      setFilter((prev) => ({
        ...prev,
        begin: (start?.valueOf() ?? 0) / 1000,
        end: (end?.valueOf() ?? 0) / 1000,
      }));
    }
  };

  const getPeriodLotteryByPeriodYear = async (year: number) => {
    try {
      const { data } = await getPeriodLotteryByPeriodYearApi(year);
      setPeriodLottery(data ?? []);
      if ((data ?? []).length > 0) {
        setSelectedPeriod(data.find((e) => e.status === 1) ?? data[0]);
      } else {
        setSelectedPeriod(undefined);
      }
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const getSummaryData = async (id: string) => {
    try {
      await Promise.all([
        getLotterySetSummaryByPeriodId(id),
        filterOrderSummary(id),
      ]);
    } catch (e: any) {
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const getLotterySetSummaryByPeriodId = async (id: string) => {
    try {
      const { data } = await getLotterySetSummaryByPeriodIdApi(id!);
      setSummary(data);
    } catch (e: any) {
      throw e;
    }
  };

  const filterOrders = async (filter, page: number, limit: number) => {
    try {
      if (filter.periodId === "") {
        setOrders([]);
        setMeta({
          page: 1,
          limit: 10,
          totalCount: 0,
          hasMore: true,
        });
        return;
      }
      const { data } = await filterOrdersApi({
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        periodId: filter.periodId,
        paymentMethod: filter.paymentMethod,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        status: !filter.status ? undefined : filter.status,
        page,
        limit,
      });

      setOrders(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const exportOrderCsv = async () => {
    try {
      setLoading(true);
      const response = await filterOrdersApi({
        begin: filter.begin !== 0 ? moment.unix(filter.begin).utc().unix() : 0,
        end: filter.end !== 0 ? moment.unix(filter.end).utc().unix() : 0,
        periodId: filter.periodId,
        paymentMethod: filter.paymentMethod,
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        status: !filter.status ? undefined : filter.status,
        type: "csv",
      });

      let BOM = "\uFEFF";
      let csvData = (BOM + response.data) as any;
      let blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(blob, `order-history.csv`);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const filterOrderSummary = async (id: string) => {
    try {
      const { data } = await filterOrderSummaryApi(id);
      setSummaryData(data);
    } catch (e: any) {
      throw e;
    }
  };

  const confirmOrder = async (id: string, status: 0 | 1) => {
    try {
      setLoading(true);
      await confirmOrderApi({ id, status });
      await Promise.all([
        getSummaryData(selectedPeriod!.id),
        filterOrders(filter, meta.page, meta.limit),
      ]);
      openSnackbar("Success");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterOrders(filter, meta.page, meta.limit);
    // eslint-disable-next-line
  }, [badge.order]);

  useEffect(() => {
    filterOrders(filter, 1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    getPeriodLotteryByPeriodYear(selectedYear);
    // eslint-disable-next-line
  }, [selectedYear]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      periodId: selectedPeriod?.id ?? "",
    }));
    if (!!selectedPeriod) {
      getSummaryData(selectedPeriod!.id);
    } else {
      setSummary(undefined);
      setSummaryData(undefined);
    }
    // eslint-disable-next-line
  }, [selectedPeriod]);

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <Breadcrumb
            title={"คำสั่งซื้อ"}
            children={[
              { title: "หน้าแรก", link: "/" },
              { title: "คำสั่งซื้อ" },
            ]}
          />

          {/* <button className="btn btn-primary ml-2">
            <i className="fa fa-shopping-cart mr-2"></i>สร้างคำสั่งซื้อ
          </button> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mb-2">
            <div className="card-body">
              <div className="row ">
                <div className="col-auto d-none d-md-block">
                  <div className="btn btn-primary">
                    <h2 className="m-0">{selectedPeriod?.period.day ?? ""}</h2>
                    <h4 className="m-0">
                      {!!selectedPeriod
                        ? moment.monthsShort(selectedPeriod!.period.month - 1)
                        : ""}
                    </h4>
                  </div>
                </div>
                <div className="col-4 border-right">
                  <div
                    className="d-flex flex-column flex-md-row"
                    style={{
                      gap: ".5rem",
                      paddingLeft: "0.625rem",
                      paddingRight: "0.625rem",
                    }}
                  >
                    <div className="form-group" style={{ minWidth: 100 }}>
                      <label className="required">ปี</label>
                      <select
                        className="form-control"
                        value={selectedYear}
                        onChange={(e) => {
                          setSelectedYear(+e.target.value);
                        }}
                      >
                        {listYear.map((year) => (
                          <option key={year} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group w-100">
                      <label className="required">งวดประจำวันที่</label>
                      <select
                        className="form-control"
                        value={selectedPeriod?.id}
                        onChange={(e) => {
                          setSelectedPeriod(
                            periodLottery.find((ee) => ee.id === e.target.value)
                          );
                        }}
                      >
                        {periodLottery.map((val) => (
                          <option key={val.id} value={val.id}>
                            งวดวันที่ {val.period.day}{" "}
                            {moment.months(val.period.month - 1)}{" "}
                            {val.period.year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {!!selectedPeriod && (
                    <span
                      className={`badge badge-${
                        selectedPeriod?.status === 1 ? "success" : "danger"
                      } h5 font-weight-bold`}
                    >
                      {selectedPeriod?.status === 1 ? "เปิด" : "ปิด"}
                    </span>
                  )}
                </div>
                <div className="col border-right pl-3">
                  <span className="mb-1 tx-12 text-muted">
                    ลอตเตอรี่ทั้งหมด
                  </span>
                  <br />
                  <span className="h2 mb-0 mr-2">
                    {selectedPeriod?.totalLottery.toLocaleString("en-US") ?? 0}
                  </span>
                </div>
                <div className="col border-right pl-3">
                  <span className="mb-1 tx-12 text-muted">ขายแล้ว</span>
                  <br />
                  <span className="h2 mb-0 mr-2">
                    {summary?.sold?.toLocaleString("en-US") ?? 0}
                  </span>
                  <span className="tx-12 mb-0 mt-3 text-success">
                    {`${(
                      ((summary?.sold ?? 0) * 100) /
                      (selectedPeriod?.totalLottery === 0
                        ? 1
                        : selectedPeriod?.totalLottery ?? 1)
                    ).toFixed(2)}%`}
                  </span>
                </div>
                <div className="col border-right- pl-3">
                  <span className="mb-1 tx-12 text-muted">คงเหลือ</span>
                  <br />
                  <span className="h2 mb-0 mr-2 text-danger">
                    {(
                      (selectedPeriod?.totalLottery ?? 0) - (summary?.sold ?? 0)
                    ).toLocaleString("en-US")}
                  </span>
                  <span className="tx-12 mb-0 mt-3">
                    {`${(
                      (((selectedPeriod?.totalLottery ?? 0) -
                        (summary?.sold ?? 0)) *
                        100) /
                      (selectedPeriod?.totalLottery === 0
                        ? 1
                        : selectedPeriod?.totalLottery ?? 1)
                    ).toFixed(2)}%`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card ">
            <div className="card-body">
              <div className="row border-bottom pb-2 mb-2">
                <div className="col mb-2 p-2 border-right">
                  <div className="card-">
                    <div className="card-body-">
                      <div className="d-flex align-items-left justify-content-md-left mb-2 mb-md-0">
                        <i className="mdi mdi-flag-outline icon-md mr-3 mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">
                            ออเดอร์ทั้งหมด
                          </p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2">
                              {summaryData?.totalOrder?.toLocaleString(
                                "en-US"
                              ) ?? 0}
                            </h3>
                            <p className="tx-12 mb-0  mt-2 text-muted">
                              รายการ
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col mb-2 border-right">
                  <div className="card-">
                    <div className="card-body-">
                      <div className="d-flex align-items-left justify-content-md-left mb-2 mb-md-0">
                        <i className="mdi mdi-trophy-outline icon-md mr-3 text-success mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">สำเร็จ/ยืนยัน</p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 text-success">
                              {summaryData?.totalSuccessOrder?.toLocaleString(
                                "en-US"
                              ) ?? 0}
                            </h3>
                            <p className="tx-12 mb-0  mt-2 text-muted">
                              รายการ
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col mb-2 border-right">
                  <div className="card-">
                    <div className="card-body-">
                      <div className="d-flex align-items-left justify-content-md-left mb-2 mb-md-0">
                        <i className="mdi mdi-timelapse icon-md mr-3 text-warning mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">รอยืนยัน</p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2  text-warning">
                              {summaryData?.totalPendingApproveOrder?.toLocaleString(
                                "en-US"
                              ) ?? 0}
                            </h3>
                            <p className="tx-12 mb-0  mt-2 text-muted">
                              รายการ
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col mb-2">
                  <div className="card-">
                    <div className="card-body-">
                      <div className="d-flex align-items-left justify-content-md-left mb-2 mb-md-0">
                        <i className="mdi mdi-alarm-check icon-md mr-3 text-info mt-2"></i>
                        <div>
                          <p className="mb-1 tx-12 text-muted">จอง</p>
                          <div className="d-flex align-items-center">
                            <h3 className="mb-0 mr-2 text-info">
                              {summaryData?.totalReservedOrder?.toLocaleString(
                                "en-US"
                              ) ?? 0}
                            </h3>
                            <p className="tx-12 mb-0  mt-2 text-muted">
                              รายการ
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg mt-2">
                  <span className="h3">
                    {!!selectedPeriod
                      ? `${selectedPeriod!.period.day} ${moment.months(
                          selectedPeriod!.period.month - 1
                        )} ${selectedPeriod!.period.year + 543}`
                      : ""}
                  </span>
                </div>

                <div className="col-md mb-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="หมายเลขคำสั่งซื้อ"
                    value={filter.orderNo}
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        orderNo: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className="col-md mb-1">
                  <ReactDatePicker
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    dateFormat={"dd/MM/yyyy"}
                    placeholderText="วันที่ จาก - ถึง"
                    isClearable={true}
                    customInput={<input className="form-control" />}
                  />
                </div>

                <div className="col-md mb-1">
                  <div className="form-group">
                    <select
                      className="form-control w-100"
                      value={filter.status}
                      onChange={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          status: e.target.value,
                        }));
                      }}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="0">ยกเลิก</option>
                      <option value="1">สำเร็จ</option>
                      <option value="2">จอง</option>
                      <option value="3">รอชำระเงิน</option>
                      <option value="5">รอยืนยัน</option>
                    </select>
                  </div>
                </div>

                <div className="col-md mb-1">
                  <div className="form-group">
                    <select
                      className="form-control w-100"
                      value={filter.paymentMethod}
                      onChange={(e) => {
                        setFilter((prev) => ({
                          ...prev,
                          paymentMethod: e.target.value,
                        }));
                      }}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="โอนเงิน">โอนเงิน</option>
                      <option value="QR Code">QR Code</option>
                    </select>
                  </div>
                </div>

                <div className="col-auto mr-2">
                  <button
                    onClick={() => {
                      exportOrderCsv();
                    }}
                    className="btn btn-outline-primary"
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-wrap table_new" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>หมายเลขคำสั่งซื้อ</th>
                      <th>วันเวลา</th>
                      <th>ผู้ซื้อ</th>
                      <th>จำนวน(ใบ)</th>
                      <th>สกุลเงิน</th>
                      <th>ราคา(บาท)</th>
                      {/* <th>ราคา(₩)</th>
                      <th>ค่าเงิน THB/KRW</th> */}
                      <th style={{ width: 150 }}>ไฟล์แนบ</th>
                      <th>การชำระเงิน</th>
                      <th>สถานะ</th>
                      <th className="text-right">ดำเนินการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length > 0 ? (
                      orders.map((order) => {
                        let status: ReactNode;
                        switch (order.status) {
                          case 0:
                            status = (
                              <span className="badge badge-small badge-danger">
                                ยกเลิก/หมดเวลา
                              </span>
                            );
                            break;
                          case 1:
                            status = (
                              <span className="badge badge-small badge-success">
                                สำเร็จ
                              </span>
                            );
                            break;
                          case 2:
                            status = (
                              <span className="badge badge-primary badge-small">
                                จอง
                              </span>
                            );
                            break;
                          case 3:
                            status = (
                              <span className="badge badge-small badge-info">
                                รอชำระเงิน
                              </span>
                            );
                            break;
                          case 4:
                            status = (
                              <span className="badge badge-small badge-warning">
                                รอยืนยัน
                              </span>
                            );
                            break;
                          case 5:
                            status = (
                              <span className="badge badge-small badge-warning">
                                รอยืนยัน
                              </span>
                            );
                            break;
                          default:
                            status = (
                              <span className="badge badge-small badge-light">
                                -
                              </span>
                            );
                        }

                        return (
                          <tr key={order.id}>
                            <td>
                              <div
                                className="hover-underline cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `${process.env.PUBLIC_URL}/orders/${order.id}`
                                  )
                                }
                              >
                                {order.orderNo}
                              </div>
                            </td>
                            <td>
                              {moment(order.createdAt).format(
                                "DD/MM/yyyy HH:mm:ss"
                              )}
                            </td>
                            <td>
                              <div
                                className="hover-underline cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `${process.env.PUBLIC_URL}/users/edit/${order.userId}`
                                  )
                                }
                              >
                                {order.user?.firstName} {order.user?.lastName}
                              </div>
                              <span className="tx-12 text-muted">
                                {order.user?.phoneNumber}
                              </span>
                            </td>
                            <td>{order.totalLottery}</td>
                            <td>{order.currency}</td>
                            <td>{order.totalAmount}</td>
                            {/* {order.currency === "KRW" ? (
                              <>
                                <td>
                                  {(
                                    order.totalAmount * order.conversionRate
                                  ).toFixed(2)}
                                </td>
                                <td>{order.conversionRate}</td>
                              </>
                            ) : (
                              <>
                                <td>-</td>
                                <td>-</td>
                              </>
                            )} */}

                            <td>
                              {order.slip.length > 0 && (
                                <div
                                  className="cursor-pointer img-thumbmail"
                                  onClick={() => {
                                    setShowImage({
                                      show: true,
                                      src: getSrcImage(order.slip),
                                    });
                                  }}
                                >
                                  <img
                                    className="lazy img-responsive"
                                    alt=""
                                    src={getSrcImage(order.slip, "?tr=w-50")}
                                  />
                                </div>
                              )}
                            </td>
                            <td>{order.paymentMethod}</td>

                            <td>{status}</td>
                            <td className="text-right">
                              <div className="dropdown">
                                <button
                                  className="btn btn-outline-primary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuIconButton2"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  เพิ่มเติม
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuIconButton2"
                                >
                                  {[2, 3, 5].includes(order.status) && (
                                    <>
                                      {order.status === 5 && (
                                        <div
                                          className="dropdown-item cursor-pointer"
                                          onClick={() => {
                                            confirmOrder(order.id, 1);
                                          }}
                                        >
                                          <span className="fa fa-check mr-2 text-success"></span>
                                          อนุมัติ/ยืนยัน
                                        </div>
                                      )}
                                      <div
                                        className="dropdown-item cursor-pointer"
                                        onClick={() => {
                                          confirmOrder(order.id, 0);
                                        }}
                                      >
                                        <span className="fa fa-times mr-2 text-danger"></span>
                                        ยกเลิก
                                      </div>
                                      <div className="dropdown-divider"></div>
                                    </>
                                  )}
                                  <div
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => {
                                      navigate(
                                        `${process.env.PUBLIC_URL}/orders/${order.id}`
                                      );
                                    }}
                                  >
                                    <span className="fa fa-edit mr-2"></span>
                                    รายละเอียด
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-center">ไม่พบรายการ</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterOrders(filter, page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};
export default OrderList;
